import { css } from "@emotion/core"

const siteLayout = css({
  maxWidth: 960,
  padding: `0 1.0875rem 1.45rem`,
  "@media(min-width: 768px)": {
    margin: `0 auto`,
    minWidth: 768,
  },
})
export default siteLayout
