/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"

import Header from "./header"
import siteLayout from "./layout-styles"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div css={{ display: `flex`, flexDirection: `column`, minHeight: `100vh` }}>
      <Header
        css={{ flexShrink: 0 }}
        siteTitle={data.site.siteMetadata.title}
      />
      <div
        css={css(
          {
            flex: `1 0 auto`,
          },
          siteLayout
        )}
      >
        <main>{children}</main>
      </div>
      <footer css={css({ flexShrink: 0 }, siteLayout)}>
        © {new Date().getFullYear()}, Built by Johannes with Gatsby
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
