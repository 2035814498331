import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"
import siteLayout from "./layout-styles"

const headerLink = css`
  &,
  &:visited {
    color: white;
    padding: 0 1rem;
  }
  &:first-of-type {
    padding-left: 0;
  }
`

const Header = ({ siteTitle }) => (
  <header
    css={{
      background: `#2486c2`,
      marginBottom: `1.45rem`,
    }}
  >
    <div css={siteLayout}>
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          css={{
            color: `white`,
            ":visited": { color: `white` },
            ":hover, :active": { color: `rgba(255, 255, 255, 0.9)` },
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <div
        css={css`
          padding: 0.5rem 0;
        `}
      >
        <Link css={headerLink} to="/puppies">
          Puppies / Pentuja
        </Link>
        <Link css={headerLink} to="/news">
          News
        </Link>
        <Link css={headerLink} to="/champions">
          Champions
        </Link>
        <Link css={headerLink} to="/about">
          About us
        </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
